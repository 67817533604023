import React from 'react';
import './Project.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


function Intro() {
  return (
    <section className="project_wrapper_section">
  <div className="project_1">
       <div className="paddings innerWidth  hero-container pro1_screen_container">
       {/*Lift side start*/}
       <div className='Left_section_project flexColStart'>
         <div className='h_pro'>-project 1</div>
         <div className='heading_pro'>Branding Nice Studio </div>
         <div className='para_pro'>The technological revolution is changing aspect of our <br />lives, and the fabric of society itself. it's also changing the <br />way we learn and what we learn. Factual knowledge <br />is less prized when everything you ever need to know can <br />be found on your phone. There's no imperative to be an <br />expert at doing everything when you can.
        </div>
        <div className='readmore_project'><span><FontAwesomeIcon icon={faArrowRight} /></span>
        Read More
        </div>
       </div>

       {/*Right side start*/}
       <div className='Right_section_project flexCenter'>
       <div className='image_container_project'>
       <img src='https://demo.phlox.pro/freelancer/wp-content/uploads/sites/272/2021/02/portfolio-1-img.jpg' alt='project1' width={600}  id="scrollingImage"/>
      
       </div>
       </div>
      {/*Right side end*/}
      </div>
      </div>

      
{/*project 2 start-----------------------------------------------------project 2*/}
      <div className="project_1 project2">
       <div className="paddings innerWidth  hero-container pro2_screen_container">
               {/*Right side start*/}
       <div className='Right_section_project_2 flexCenter'>
       <div className='image_container_project_2'>
       <img src='https://demo.phlox.pro/freelancer/wp-content/uploads/sites/272/2021/02/portfolio-1-img.jpg' alt='project1' width={600}  id="scrollingImage"/>
      
       </div>
       </div>
      {/*Right side end*/}
       {/*Left side start*/}
       <div className='Left_section_project flexColStart_2'>
         <div className='h_pro'>-project 2</div>
         <div className='heading_pro'>Mobile Card App </div>
         <div className='para_pro'>The technological revolution is changing aspect of our lives, and the fabric of society itself. it's also changing the way we learn and what we learn. Factual knowledge is less prized when everything you ever need to know can be found on your phone. There's no imperative to be an expert at doing everything when you can.
        </div>
        <div className='readmore_project'><span><FontAwesomeIcon icon={faArrowRight} /></span>
        Read More
        </div>
       </div>
       {/*Left side end*/}


      </div>
      </div>
      {/*End of project-2-----------------------------------*/}
      
      
      
      {/*project 3 start-----------------------------------------------------project 3*/}
      <div className="project_1 project3">
       <div className="paddings innerWidth  hero-container pro1_screen_container">
       {/*Lift side start*/}
       <div className='Left_section_project flexColStart'>
         <div className='h_pro'>-project 3</div>
         <div className='heading_pro'>Resturant Landing Page </div>
         <div className='para_pro'>The technological revolution is changing aspect of our <br />lives, and the fabric of society itself. it's also changing the <br />way we learn and what we learn. Factual knowledge <br />is less prized when everything you ever need to know can <br />be found on your phone. There's no imperative to be an <br />expert at doing everything when you can.
        </div>
        <div className='readmore_project'><span><FontAwesomeIcon icon={faArrowRight} /></span>
        Read More
        </div>
       </div>

       {/*Right side start*/}
       <div className='Right_section_project flexCenter'>
       <div className='image_container_project'>
       <img src='https://demo.phlox.pro/freelancer/wp-content/uploads/sites/272/2021/02/portfolio-1-img.jpg' alt='project1' width={600}  id="scrollingImage"/>
      
       </div>
       </div>
      {/*Right side end*/}
      </div>
      </div>
      {/*project 3 END-----------------------------------------------------project 3*/}

      <center><button className='btn2 bttn btnn_screen'>View All</button></center>


    </section>
    
  );
}
window.addEventListener('scroll', function() {
    var imageContainer = document.querySelector('.image_container_project');
    var containerPosition = imageContainer.getBoundingClientRect().top;
    var screenHeight = window.innerHeight;
 
    if (containerPosition - screenHeight <= 0) {
       imageContainer.classList.add('visible');
    }
 });
 

export default Intro;
