import React, { useState } from 'react';
import './header.css';
import Header from '../Header/header.jsx';
import Footers from '../Footer/footer.jsx';
import Port from "../Portfolio_page/Portfolio.jsx";
import { BiMenuAltRight } from 'react-icons/bi';
function App() {
  const [menuopen, setmenuopen] = useState(false);

  const getMenustyle = (menuopen) => {
    if (document.documentElement.clientWidth <= 800) {
      return { right: menuopen ? "-100%" : undefined };
    } else {
      return {}; // or provide a default return value
    }
  };
  return (
    <section>
        <div className="h_wrapper">
         <div className="h_wrapper2">
           <div className="img_c paddings h-container size_que_image">
           <img src='	https://demo.phlox.pro/freelancer/wp-content/uploads/sites/272/2021/02/logo-header.svg' alt='logo' width={100} />
           </div>
           <div onOutsideClick={() => {
            setmenuopen(false);
          }}>
           <div className="h-menu flexCenter " style={getMenustyle(menuopen)}>
              <a href="/" className="selected">Home</a>
              <a href="/">Portfolio</a>
              <a href="/">Contact</a>
           </div>
           </div>

        </div>

 
            <div className="end_nav end_nav_block">
            <button className="btn1">
            <img src='./nev-icon-orange.png' alt='logo' width={20} />
              </button>
            <button className="btn2 bt_contact_block">
                Contact
              </button>

            </div>
            <div className="menu-icon" onClick={() => setmenuopen(prev => !prev)}>
            <BiMenuAltRight size={30} color='white' />
          </div>
            

        </div>
        
    </section>
  );
}

export default App;
