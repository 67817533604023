import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./Component/Header/header";
import Intro from "./Component/Intro_sec/Intro";
import Skill from "./Component/Skill_sec/skill";
import Project from "./Component/Project_section/Project";
import Circle from "./Component/Circle/Circle_gree";
import State from "./Component/State/State";
import Work from "./Component/Work_section/Work_con";
import Foooters from "./Component/Footer/footer";
import Port from "./Component/Portfolio_page/Portfolio";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<div className="App">
          <div>
            <Header />
            <Intro />
          </div>
          <Skill />
          <Project />
          <Circle />
          <State />
          <Work />
          <Foooters />
        </div>} />
        <Route path="/Port" element={<Port />} />
      </Routes>
    </Router>
  );
}

export default App;
